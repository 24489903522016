import {EuiCallOut, EuiSpacer} from '@elastic/eui';
import React from "react";

const AlphaBanner = () =>
    <>
        <EuiCallOut
            title="This platform is currently in alpha. Thank you for your continued support!"
            iconType="beaker"
            size="s"
        >
        </EuiCallOut>
        <EuiSpacer/>
    </>
;

export default AlphaBanner