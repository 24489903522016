import {
    EuiButton,
    EuiPopover,
    EuiButtonIcon, EuiFieldText, EuiForm, EuiFormRow, EuiTextArea,
    EuiText,
    EuiSwitch,
    EuiSpacer
} from '@elastic/eui';
import React, {useState} from "react";
import {decodeAuthToken} from "../service/authService";
import {useHistory} from "react-router";
import axios from "axios";

//TODO remove
const backend_url = process.env.REACT_APP_BACKEND_URL;


const FeedbackButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isBug, setIsBug] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(false);
    const [successfullySent, setSuccessfullySent] = useState(false);

    let history = useHistory();

    const button = (
        <EuiButtonIcon
            display="base"
            size="m"
            iconType="pencil"
            onClick={() => setIsOpen(!isOpen)}
        />
    );

    const sendFeedback = () => axios.post(backend_url + '/feedback', {
        username: decodeAuthToken()?.username,
        isBug: isBug,
        page: history.location.pathname,
        feedback: feedback
    }, {withCredentials: true})
        .then(() => {
            setLoading(false);
            setFeedback('');
            setSuccessfullySent(true);

        }).catch(error => {
            console.error(error);
            setLoading(false);

        });

    const feedbackForm = (
        <EuiForm>
            <EuiText>
                Send feedback!
            </EuiText>
            <EuiText
                size="s"
                color="subdued"
            >
                If you're reporting a bug, please be descriptive!
            </EuiText>
            {
                successfullySent ?
                    <EuiText
                        size="s"
                        color="secondary"
                    >
                        Feedback sent! Thanks!
                    </EuiText>: null
            }
            <EuiSpacer/>
            <EuiFormRow label="Username">
                <EuiFieldText
                    value={decodeAuthToken()?.username}
                    disabled={true}
                    compressed={true}
                />
            </EuiFormRow>
            <EuiSpacer/>
            <EuiSwitch
                label="I am reporting a bug"
                checked={isBug}
                compressed={true}
                onChange={(e)=> {
                    setIsBug(e.target.checked)
                }}
            />
            <EuiSpacer/>
            { isBug ?
                <EuiFormRow label="Current page">
                    <EuiFieldText
                        value={history.location.pathname}
                        disabled={true}
                        compressed={true}
                    />
                </EuiFormRow> : null
            }
            <EuiFormRow label="Feedback">
                <EuiTextArea
                    compressed={true}
                    value={feedback}
                    onChange={(e) => {
                        setFeedback(e.target.value)
                    }}
                    disabled={false}
                />
            </EuiFormRow>
            <EuiButton type="submit"
                       size="s"
                       isLoading={loading}
                       disabled={(feedback && feedback.length === 0) || successfullySent }
                       onClick={() => {
                           setLoading(true);
                           sendFeedback();
                       }}
                       compressed={true}
            >
                Save
            </EuiButton>
        </EuiForm>
    );

    return <EuiPopover
        button={button}
        isOpen={isOpen}
        closePopover={() => {
            setIsOpen(false);
            setSuccessfullySent(false);
        }}
        style={{position: 'fixed', bottom: "1em", right: "1em", zIndex: 10}}
        repositionOnScroll={true}>
        {feedbackForm}
    </EuiPopover>

};

export default FeedbackButton;