import React, {useEffect, useState} from 'react';
import {
    EuiFlexGrid,
    EuiCard,
    EuiFlexItem,
    EuiPage,
    EuiPageBody,
    EuiEmptyPrompt,
    EuiImage,
    EuiLoadingSpinner,
    EuiButtonIcon,
    EuiText,
    EuiFlexGroup,
    EuiAvatar,
    EuiHideFor
} from '@elastic/eui';
import Header from "./Header";
import {useHistory} from "react-router";
import axios from "axios";
import AlphaBanner from "./AlphaBanner";
import {decodeAuthToken} from "../service/authService";
import PatchNotes from "./PatchNotes";

const backend_url = process.env.REACT_APP_BACKEND_URL;

const Streams = () => {
    //const [hover, setHover] = useState(false);
    const [liveUsers, setLiveUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPatchNotes, setShowPatchNotes] = useState(false);
    let history = useHistory();

    const renderStream = () =>
        liveUsers.length ? liveUsers.map(user =>
            <EuiFlexItem onClick={() => history.push(`/stream/${user.username}`)}>
                <StreamCard user={user}/>
            </EuiFlexItem>) : noStreams
    ;

    const noStreams = <EuiEmptyPrompt
        title={<h2>No Livestream's found</h2>}
        body={
            <EuiImage
                size='m'
                src={`${process.env.REACT_APP_BACKEND_URL}/static/images/sadpanda.png`}
            />
        }
        actions={<EuiButtonIcon iconType='refresh' onClick={() => history.go(0)}/>}
    />;

    useEffect(() => {
            axios.get(backend_url + '/active-streams', {withCredentials: true})
                .then(response => {
                    setLiveUsers(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.log("Something went wrong");
                    setLoading(false);
                })
        }
        , []);

    useEffect(() => {
        setShowPatchNotes(localStorage.getItem('patch_flag') !== process.env.REACT_APP_VERSION);
    }, []);


    return (
        <div>
            <EuiPage>
                <EuiPageBody>
                    <AlphaBanner/>
                    <PatchNotes show={showPatchNotes} closePatchNotes={() => {
                        setShowPatchNotes(false);
                        localStorage.setItem("patch_flag", process.env.REACT_APP_VERSION)
                    }}/>
                    <EuiFlexGrid style={{"justifyContent": "center"}} columns={3}>
                        {loading ? <EuiLoadingSpinner size="l"/> : renderStream()}
                    </EuiFlexGrid>
                </EuiPageBody>
            </EuiPage>
        </div>
    )
};

const StreamCard = ({user}) => {
    const [hover, setHover] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);
    const [animatedThumbnail, setAnimatedThumbnail] = useState(null);

    // https://stackoverflow.com/questions/4968250/how-to-round-time-to-the-nearest-quarter-hour-in-javascript
    const roundedTime = () => {
        let minutes = new Date().getMinutes();
        let hours = new Date().getHours();

        let m = (((minutes + 7.5) / 15 | 0) * 15) % 60;
        let h = ((((minutes / 105) + .5) | 0) + hours) % 24;

        return `${m}-${h}`;
    };

    useEffect(() => {
        setThumbnail(`${process.env.REACT_APP_BACKEND_URL}/static/images/thumbnails/live_${user.username}_static.png?t=${roundedTime()}`);
        setAnimatedThumbnail(`${process.env.REACT_APP_BACKEND_URL}/static/images/thumbnails/live_${user.username}_animated.gif?t=${roundedTime()}`);
    }, [user]);

    return (
        <EuiCard
            textAlign="left"
            onMouseOver={() => {
                setHover(true)
            }}
            onMouseOut={() => {
                setHover(false)
            }}
            image={hover ? animatedThumbnail : thumbnail}
            onError={() => {
                setThumbnail("https://unicornriot.ninja/wp-content/uploads/2018/09/LiveChannel334x212v2.png");
                setAnimatedThumbnail("https://unicornriot.ninja/wp-content/uploads/2018/09/LiveChannel334x212v2.png")
            }}
            title={user.streamTitle}
            description={<p>{user.streamDescription}</p>}
            footer={
                <EuiFlexGroup>
                    <EuiHideFor sizes={['s', 'xs']}>
                        <EuiFlexItem grow={false}>
                            <EuiAvatar
                                name={`${decodeAuthToken()?.username}`}
                                size="s"
                                imageUrl={`${process.env.REACT_APP_BACKEND_URL}/static/images/avatar/${user.username}.jpeg`}
                            />
                        </EuiFlexItem>
                    </EuiHideFor>
                    <EuiFlexItem>
                        <p>{user.username}</p>
                    </EuiFlexItem>
                </EuiFlexGroup>
            }

        />)

};

export default Streams;