import videojs from 'video.js'
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import axios from "axios";
import {VideoPlayer} from "./VideoPlayer";
import {
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiSpacer,
    EuiPageSideBar,
    EuiText,
    EuiAccordion,
    EuiResizableContainer,
    EuiLoadingContent,
    EuiCallOut,
    EuiAvatar,
    EuiHeaderSectionItemButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiBadge
} from '@elastic/eui';
import useWindowDimensions from "../hooks/useWindowDimensions";
import {useIsWithinBreakpoints} from '@elastic/eui/lib/services';
import {useHistory} from "react-router";
import AlphaBanner from "./AlphaBanner";
import {decodeAuthToken} from "../service/authService";
import {io} from "socket.io-client";


const backend_url = process.env.REACT_APP_BACKEND_URL;

const Stream = () => {
    // const {width} = useWindowDimensions();
    // console.log(width)
    // let direction = useCallback(() => {
    //     console.log(`WIDTH ${width}`)
    //    return width <= 920 ? "vertical" : "horizontal"
    // }, [width]);

    //const isMobile = useIsWithinBreakpoints(['xs', 's']);
    const [streamMetadata, setStreamMetadata] = useState(null);
    const [loading, setLoading] = useState(true);
    const [viewers, setViewers] = useState(1);

    let socket;

    let history = useHistory();

    useEffect(() => {
            axios.get(backend_url + `/user/${history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1)}/stream`, {withCredentials: true})
                .then(response => {
                    console.log(response.data);
                    setStreamMetadata(response.data);
                    setViewers(response.data?.viewers ?? 1);
                    setLoading(false);
                })
                .catch(error => {
                    console.log("Something went wrong");
                    setLoading(false);
                })
        }
        , []);

    useEffect(() => {
        if (!socket)
            socket = io(process.env.REACT_APP_BACKEND_SOCKET);
        if (socket) {
            socket.emit('join', {streamer: history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1)});
            socket.on('heartbeat', (data) => {
                console.log('HEARTBEAT');
                setViewers(data.viewers);
            })
        }
    }, []);

    const streamLabels = () =>
        <EuiFlexGroup>
            <EuiFlexItem grow={false}>
                <EuiAvatar
                    name={`${decodeAuthToken()?.username}`}
                    size="xl"
                    imageUrl={`${process.env.REACT_APP_BACKEND_URL}/static/images/avatar/${history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1)}.jpeg`}
                />
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiText>
                    <h2>{streamMetadata.streamTitle}</h2>
                    <p>{streamMetadata.streamDescription}</p>
                </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <div>
                    {/* https://eui.elastic.co/#/layout/flex Heading: Flex items are also flex*/}
                    <EuiBadge color="#ff8280" iconType="eye">{viewers}</EuiBadge>
                </div>
            </EuiFlexItem>

        </EuiFlexGroup>;

    return (
        <div>
            <EuiPage restrictWidth={"80%"}>
                <EuiPageBody component="div">
                    <AlphaBanner/>
                    <EuiPageContent>
                        <VideoPlayer
                            username={history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1)}/>
                        <EuiSpacer/>
                        {
                            loading ? <EuiLoadingContent lines={1}/> : streamLabels()
                        }
                    </EuiPageContent>
                </EuiPageBody>
            </EuiPage>
        </div>

    )
};

export default Stream;

//http://192.168.1.202:8888/live/muhammadtalhas/index.m3u8