import React, {useRef, useEffect} from "react";
import videojs from "video.js";
import {
    EuiCallOut,
    EuiSpacer
} from '@elastic/eui';
import 'video.js/dist/video-js.min.css';


export const VideoPlayer = ({username}) => {
    const videoPlayerRef = useRef(null); // Instead of ID
    let retry_counter = 0;

    const videoJSOptions = {
        autoplay: "muted",
        controls: true,
        preload: 'auto',
        sources: [
            {
                src: `${process.env.REACT_APP_MEDIA_SERVER_URL}/${username}/index.m3u8`,
                type: 'application/x-mpegURL'
            },
            {
                src: 'https://ak.picdn.net/shutterstock/videos/19876828/preview/stock-footage-distortion-and-flickering-analog-tv-signal.webm'
            }
        ],
        fluid: true
    };
    let player = useRef(null);

    useEffect(() => {
        if (videoPlayerRef) {
            player = videojs(videoPlayerRef.current, videoJSOptions, () => {
                player.tech().on('retryplaylist', (event) => {
                        console.log('retryplaylist: ', event);
                        if (retry_counter > 10) {
                            console.log('something is wrong, time to do something')
                        } else {
                            retry_counter++;
                        }
                    }
                );
                player.on('play', function () {
                    console.log('PLAY')
                });

                let vhs = player.tech().vhs;
                console.log(vhs.stats);

            });
        }
        return () => {
            console.log('disposing');
            player.dispose();
        };
    }, []);


    return (
        <div>
            <video-js
                ref={videoPlayerRef}
                className="video-js vjs-big-play-centered"
            />
            <EuiSpacer/>
            <EuiCallOut title="The video player is under continued development. If you encounter issues, refresh the page." color="warning" iconType="beaker" />
        </div>
    );
};
