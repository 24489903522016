import {
    Route,
    Redirect
} from "react-router-dom";
import React from "react";
import {EuiLoadingSpinner} from '@elastic/eui';

const ProtectedRoute = ({authenticated, ...props}) => {
    console.log(authenticated)
    if (authenticated === '') {
        return <EuiLoadingSpinner size="xl"/>
    }

    return authenticated
        ? <Route {...props}/>
        : <Redirect to="/login"/>;
};

export default ProtectedRoute;