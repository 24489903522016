import {
    EuiButton,
    EuiFieldPassword,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiImage,
    EuiLink,
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageContentHeader,
    EuiPageContentHeaderSection,
    EuiSpacer,
    EuiText,
    EuiTitle,
    EuiPageHeaderSection,
    EuiCallOut,
    EuiCopy,
    EuiTextArea,
    EuiAvatar,
    EuiAccordion,
    EuiFilePicker,
    EuiFlexGroup,
    EuiOverlayMask,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiModalFooter,
    EuiButtonEmpty,
    EuiRange,
    EuiGlobalToastList,
    EuiCode,
    EuiIcon
} from '@elastic/eui';
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import Header from "./Header";
import AvatarEditor from 'react-avatar-editor'
import {useHistory} from "react-router";
import isEqual from 'lodash/isEqual';
import AlphaBanner from "./AlphaBanner";


//TODO remove
const backend_url = process.env.REACT_APP_BACKEND_URL;

const Settings = () => {
    const [loading, setLoading] = useState(true);

    const [userSettings, setUserSettings] = useState({});
    const [streamSettings, setStreamSettings] = useState({});
    const [streamSettingsUnchanged, setStreamSettingsUnchanged] = useState({});
    const [editing, setEditing] = useState(false);
    const [file, setFile] = useState(null);

    const [fileFail, setFileFail] = useState(false);
    const [scale, setScale] = useState(1);
    const [toasts, setToasts] = useState([]);

    const [saveable, setSavable] = useState(false);

    const avatarEditor = useRef(null);

    let history = useHistory();

    const failedGeneric = {
        title: 'Oops, there was an error',
        color: 'danger',
        iconType: 'help',
        text: <p>Something went wrong, please try again.</p>
    };

    const failedImageUploadToast = {
        title: 'Oops, there was an error',
        color: 'danger',
        iconType: 'help',
        text: <p>Accepted file types are <EuiCode>.jpeg</EuiCode> and <EuiCode>.png</EuiCode></p>
    };

    const failedKeyGen = {
        title: 'Oops, there was an error',
        color: 'danger',
        iconType: 'help',
        text: <p>Something went wrong securely generating a new key</p>
    };

    const successKeyGen = {
        title: 'New Key Issued!',
        color: 'success',
        iconType: 'faceHappy',
        text: <p>Your old key has been revoked, please update your streaming client!</p>
    };

    const successStreamInfoUpdate = {
        title: 'Stream Information Updated!',
        color: 'success',
        iconType: 'faceHappy',
        text: <p>How Exciting!</p>
    };

    useEffect(() => {
        axios.get(backend_url + '/getUserData', {withCredentials: true})
            .then(response => {
                let {username, email, streamKey, allowedToStream, streamTitle, streamDescription} = response.data;
                setUserSettings({username, email, allowedToStream});
                setStreamSettings({streamTitle, streamDescription, streamKey});
                setStreamSettingsUnchanged({streamTitle, streamDescription, streamKey});
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            })
    }, []);
    useEffect(() => setSavable(!isEqual(streamSettings, streamSettingsUnchanged)), [streamSettings, streamSettingsUnchanged]);

    const changeStreamTitle = newValue => setStreamSettings({...streamSettings, streamTitle: newValue});
    const changeDescription = newValue => setStreamSettings({...streamSettings, streamDescription: newValue});

    const uploadPhoto = () => avatarEditor.current.getImageScaledToCanvas().toBlob((blob) => {
        let formData = new FormData();
        formData.append("avatar", blob);
        axios.post(backend_url + '/avatar', formData, {withCredentials: true})
            .then(() => {
                    setEditing(false);
                    setFile(null);
                    setLoading(false);
                    setScale(1);
                    history.go(0);
                }
            ).catch(error => {
                console.log(error);
                setToasts(toasts.concat(failedImageUploadToast));
            }
        )
    });

    const deletePhoto = () =>
        axios.delete(backend_url + '/avatar', {withCredentials: true})
            .then(() => {
                    setLoading(false);
                    history.go(0)
                }
            ).catch(error => {
            console.log(error);
            setToasts(toasts.concat(failedGeneric));
        });


    const regenerateKey = () => axios.patch(backend_url + '/generateNewKey', {}, {withCredentials: true})
        .then((response) => {
            setLoading(false);
            let {streamKey} = response.data;
            setStreamSettings({...streamSettings, streamKey});
            setStreamSettingsUnchanged({...streamSettingsUnchanged, streamKey});
            setToasts(toasts.concat(successKeyGen));
        }).catch(error => {
            console.error(error);
            setToasts(toasts.concat(failedKeyGen));
            setLoading(false);
        });

    const updateStreamInfo = () => axios.patch(backend_url + '/updateStreamInfo', {
        streamTitle: streamSettings.streamTitle,
        streamDescription: streamSettings.streamDescription
    }, {withCredentials: true})
        .then((response) => {
            setLoading(false);
            let {streamKey, streamTitle, streamDescription} = response.data;
            setStreamSettings({streamTitle, streamDescription, streamKey});
            setStreamSettingsUnchanged({streamTitle, streamDescription, streamKey});
            setToasts(toasts.concat(successStreamInfoUpdate));
        }).catch(error => {
            console.error(error);
            setToasts(toasts.concat(failedKeyGen));
            setLoading(false);
        });

    const removeToast = removedToast => setToasts(toasts.filter((toast) => toast.id !== removedToast.id));

    const formHeader = title =>
        <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
                <EuiTitle>
                    <h2>{title}</h2>
                </EuiTitle>
            </EuiPageContentHeaderSection>
        </EuiPageContentHeader>;

    const userSettingsForm = () =>
        <EuiPageContentBody>
            <EuiForm>
                <EuiFormRow label="Username">
                    <EuiFieldText
                        value={userSettings.username}
                        readOnly
                    />
                </EuiFormRow>
                <EuiFormRow label="Email">
                    <EuiFieldText
                        value={userSettings.email}
                        readOnly
                    />
                </EuiFormRow>
                <EuiFormRow label="Avatar">
                    <EuiFlexGroup justifyContent="spaceAround">
                        <EuiAvatar
                            size="xl"
                            name={userSettings.username ? userSettings.username : ""}
                            imageUrl={`${process.env.REACT_APP_BACKEND_URL}/static/images/avatar/${userSettings.username}.jpeg`}
                        />
                    </EuiFlexGroup>
                </EuiFormRow>
                <EuiFormRow>
                    <EuiAccordion
                        id="upload_toggle"
                        buttonContent="Change your Avatar"
                        arrowDisplay="none"
                    >
                        <EuiText>
                            <EuiFilePicker
                                id='avatar-picker'
                                accept="image/png, image/jpeg"
                                initialPromptText="Select or drag and drop a file"
                                onChange={(file) => {
                                    setFileFail(false);
                                    if (file.length)
                                        setEditing(true);
                                    setFile(file)
                                }}
                            />
                        </EuiText>
                        <EuiSpacer size="s"/>
                        <EuiButtonEmpty
                            color="danger"
                            iconType="trash"
                            onClick={(e) => {
                                e.preventDefault();
                                setLoading(true);
                                deletePhoto();
                            }}>
                            Clear Avatar
                        </EuiButtonEmpty>
                    </EuiAccordion>
                </EuiFormRow>
            </EuiForm>
        </EuiPageContentBody>;

    const streamSettingsForm = () =>
        <EuiPageContentBody>
            <EuiForm>
                <EuiFormRow label="Stream Title">
                    <EuiFieldText
                        value={streamSettings.streamTitle}
                        onChange={(e) => {
                            changeStreamTitle(e.target.value)
                        }}
                        disabled={loading}
                    />
                </EuiFormRow>
                <EuiFormRow label="Stream Description">
                    <EuiTextArea
                        value={streamSettings.streamDescription}
                        onChange={(e) => changeDescription(e.target.value)}
                        disabled={loading}
                    />
                </EuiFormRow>

            </EuiForm>
        </EuiPageContentBody>;

    const streamKeyForm = () =>
        <EuiPageContentBody>
            <EuiForm>
                <EuiFormRow>
                    <EuiCallOut title="Dont share your stream key!" color="warning" iconType="help">
                        <p>
                            Never share your stream key or show it on stream! Click <EuiLink>here</EuiLink> to learn how
                            to begin streaming.
                        </p>
                    </EuiCallOut>
                </EuiFormRow>
                <EuiFormRow label="Stream Key">
                    <EuiFieldPassword
                        value={`${userSettings.username}?sign=${streamSettings.streamKey}`}
                        type="dual"
                        readonly
                    />
                </EuiFormRow>
                <EuiFormRow>
                    <EuiCopy textToCopy={`${userSettings.username}?sign=${streamSettings.streamKey}`}>
                        {(copy) =>
                            <EuiLink
                                disabled={loading}
                                onClick={e => {
                                    e.preventDefault();
                                    copy();
                                }}>
                                Copy Stream Key
                            </EuiLink>
                        }
                    </EuiCopy>
                </EuiFormRow>
                <EuiFormRow>
                    <EuiLink
                        disabled={loading}
                        onClick={(e) => {
                            e.preventDefault();
                            setLoading(true);
                            regenerateKey();
                        }}
                    >
                        Regenerate Key
                    </EuiLink>
                </EuiFormRow>
            </EuiForm>
        </EuiPageContentBody>;

    const avatarEditModal = () =>
        <EuiOverlayMask>
            <EuiModal onClose={() => {
                setEditing(false);
                setFile(null);
                setScale(1);
            }}>
                <EuiModalHeader>
                    <EuiModalHeaderTitle>Lets get your best side</EuiModalHeaderTitle>
                </EuiModalHeader>
                <EuiModalBody>
                    <AvatarEditor
                        image={file[0]}
                        ref={avatarEditor}
                        width={250}
                        height={250}
                        border={50}
                        scale={scale}
                        borderRadius={150}
                        color={[255, 255, 255, 0.6]}
                        rotate={0}
                        onLoadFailure={() => {
                            setFileFail(true);
                            setToasts(toasts.concat(failedImageUploadToast))
                        }}
                    />
                    <EuiRange
                        min={1}
                        max={2}
                        step={0.02}
                        value={scale}
                        onChange={(e) => setScale(e.target.value)}
                    />
                </EuiModalBody>

                <EuiModalFooter>
                    <EuiButtonEmpty onClick={() => {
                        setEditing(false);
                        setFile(null);
                        setScale(1);
                    }}>Cancel</EuiButtonEmpty>

                    <EuiButton
                        onClick={() => {
                            setLoading(true);
                            uploadPhoto();
                        }}
                        disabled={fileFail}
                        fill
                    >
                        Save
                    </EuiButton>
                </EuiModalFooter>
            </EuiModal>
        </EuiOverlayMask>;

    return (
        <div>
            <EuiPage>
                <EuiPageBody component="div" restrictWidth={400}>
                    <AlphaBanner/>
                    <EuiPageContent>
                        {editing ? avatarEditModal() : null}
                        {formHeader("User Settings")}
                        {userSettingsForm()}
                        <EuiSpacer/>
                        {formHeader("Stream Settings")}
                        {streamSettingsForm()}
                        <EuiSpacer/>
                        {streamKeyForm()}
                        <EuiSpacer/>
                        <EuiButton type="submit"
                                   size="s"
                                   disabled={!saveable}
                                   onClick={() => {
                                       setLoading(true);
                                       updateStreamInfo();
                                   }}
                        >
                            Save
                        </EuiButton>
                    </EuiPageContent>
                </EuiPageBody>
            </EuiPage>
            <EuiGlobalToastList
                toasts={toasts}
                dismissToast={removeToast}
                toastLifeTimeMs={3000}
            />
        </div>
    )
};

export default Settings;