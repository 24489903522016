import {EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiModalBody, EuiModalFooter, EuiButton} from '@elastic/eui';
import {notes} from "../resources/patchnotes"
import React from "react";

const PatchNotes = ({show, closePatchNotes}) =>
    show ? <>
        <EuiModal onClose={() => closePatchNotes()}>
            <EuiModalHeader>
                <EuiModalHeaderTitle><h1>Patch Notes</h1></EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                {notes}
            </EuiModalBody>
        </EuiModal>
    </> : null
;


export default PatchNotes