import React, {useState} from 'react';
import {
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageContentHeader,
    EuiPageContentHeaderSection,
    EuiTitle,
    EuiImage,
    EuiFieldText,
    EuiFieldPassword,
    EuiForm,
    EuiFormRow,
    EuiButton,
    EuiSpacer,
    EuiLink,
    EuiText,
    EuiCallOut
} from '@elastic/eui';
import {useHistory} from "react-router";
import axios from "axios";
import AlphaBanner from "./AlphaBanner";

//TODO
const backend_url = process.env.REACT_APP_BACKEND_URL;

const Register = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [loading, setLoading] = useState(false);

    const [conflictingField, setConflictingField] = useState([]);

    let history = useHistory();
    let emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    let passwordPattern = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/);

    const submit = e => {
        e.preventDefault();
        setLoading(true);
        setConflictingField([]);
        axios.post(backend_url + '/register', {email, password, username})
            .then(response => {
                history.push('/login');
                setLoading(false);
            })
            .catch(error => {
                if(error.response.status === 400)
                    setConflictingField(error.response.data.key);
                setLoading(false);
            })
    };

    const registrationError = () => {
        return <>
            <EuiCallOut title="Something went wrong" color="danger" iconType="alert">
                <p>{`Provided ${conflictingField[0]} is already being used`}</p>
            </EuiCallOut>
            <EuiSpacer/>
        </>
    };

    const emailIsValid = () => emailPattern.test(email);

    const usernameIsValid = () => username.length >= 5 && username.length <= 25;

    const passwordIsValid = () => passwordPattern.test(password);

    const passwordsMatch = () => confirmPassword === password;

    const isSubmittable = () => emailIsValid() && usernameIsValid() && passwordIsValid() && passwordsMatch();

    return (
        <EuiPage>
            <EuiPageBody component="div">
                <AlphaBanner/>
                <EuiPageContent verticalPosition="center" horizontalPosition="center">
                    <EuiPageContentHeader>
                        <EuiPageContentHeaderSection>
                            <EuiImage
                                size="l"
                                alt="talhaflix.live"
                                url="https://i.imgur.com/2N2OWyg.png"
                            />
                        </EuiPageContentHeaderSection>
                    </EuiPageContentHeader>
                    <EuiPageContentBody>
                        {conflictingField.length ? registrationError(): null}
                        <EuiForm>
                            <EuiFormRow
                                isInvalid={email === '' ? false : !emailIsValid()}
                                label="Email"
                                error={["Invalid Email Format"]}
                            >
                                <EuiFieldText name="email" onChange={(e) => setEmail(e.target.value)}/>
                            </EuiFormRow>
                            <EuiFormRow
                                isInvalid={username === '' ? false : !usernameIsValid()}
                                label="Username"
                                error={["Username must be between 5 and 25 characters"]}
                            >
                                <EuiFieldText name="username" onChange={(e) => setUsername(e.target.value)}/>
                            </EuiFormRow>
                            <EuiFormRow
                                isInvalid={password === '' ? false : !passwordIsValid()}
                                label="Password"
                                error={["Passwords needs an uppercase letter, lowercase letter, and a number"]}
                            >
                                <EuiFieldPassword name="password" onChange={(e) => setPassword(e.target.value)}/>
                            </EuiFormRow>
                            <EuiFormRow isInvalid={confirmPassword === '' ? false : !passwordsMatch()}
                                        label="Confirm Password"
                                        error={["Passwords dont match"]}
                            >
                                <EuiFieldPassword autoComplete="off" name="confirm-password" onChange={(e) => setConfirmPassword(e.target.value)}/>
                            </EuiFormRow>
                            <EuiButton isDisabled={!isSubmittable()} isLoading={loading} type="submit" size="s" onClick={(e) => submit(e)}>
                                Sign Up
                            </EuiButton>
                            <EuiSpacer size="s"/>
                            <EuiText size='s'>Already have an account? <EuiLink onClick={() => {
                                history.push('/login')
                            }} size="s">Sign in</EuiLink></EuiText>
                        </EuiForm>
                    </EuiPageContentBody>
                </EuiPageContent>
            </EuiPageBody>
        </EuiPage>
    )
};

export default Register;