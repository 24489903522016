import React from "react";
import {EuiText} from '@elastic/eui';

export let notes = <>
    <EuiText>
        <h3>Version 0.0.3</h3>
        <ul>
            <li>Live view counter added (early version)</li>
        </ul>
    </EuiText>
    <EuiText>
        <h3>Version 0.0.2</h3>
        <ul>
            <li>Feedback Button added - Send Feedback and report bugs!!</li>
            <li>CacheBusting the thumbnail(s)</li>
        </ul>
    </EuiText>
    <EuiText>
        <h3>Version 0.0.1</h3>
        <ul>
            <li>Support logging in with Email or Username</li>
            <li>Proper error handling added to login flow</li>
            <li>Proper error callouts during registration flow</li>
            <li>Resizing callouts</li>
            <li>Streamline login flow</li>
            <li>Added this cool patch notes modal</li>
            <li>You can now logout</li>
            <li>Redesign header and profile button</li>
            <li>Fix settings page layout</li>
            <li>Top left area of the header now links to homepage</li>
            <li>Video Player and stream page tune up</li>
            <li>Stream card responsiveness fixes and slight redesign</li>
        </ul>
    </EuiText>
</>;

