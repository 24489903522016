import React, {useState, useEffect} from 'react';
import {
    EuiHeader,
    EuiHeaderLogo,
    EuiHeaderSectionItem,
    EuiHeaderLinks,
    EuiHeaderLink,
    EuiAvatar,
    EuiPopover,
    EuiHeaderSectionItemButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiLink,
    EuiSpacer,
} from '@elastic/eui';
import {useHistory} from "react-router";
import {decodeAuthToken} from "../service/authService";

const Header = ({logout}) => {
    const [path, setPath] = useState('');
    let history = useHistory();

    useEffect(() => {
            setPath(history.location.pathname.match(/^\/[^\/]+/)[0].substring(1))
        console.log(path)
        },
        [history.location]
    );

    return (
        <EuiHeader position="fixed">
            <EuiHeaderSectionItem onClick={() => history.push('/streams')}
                                  border="right">
                <EuiHeaderLogo>TalhaFlix</EuiHeaderLogo>
            </EuiHeaderSectionItem>
            <EuiHeaderSectionItem border="left">
                <HeaderUserMenu logout={logout}/>
            </EuiHeaderSectionItem>
        </EuiHeader>
    )
};

const HeaderUserMenu = ({logout}) => {
    const [isOpen, setIsOpen] = useState(false);
    let history = useHistory();

    const button = <EuiHeaderSectionItemButton
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-label="Account menu"
        onClick={() => setIsOpen(!isOpen)}>
        <EuiAvatar
            name={`${decodeAuthToken()?.username}`}
            size="s"
            imageUrl={`${process.env.REACT_APP_BACKEND_URL}/static/images/avatar/${decodeAuthToken()?.username}.jpeg`}
        />
    </EuiHeaderSectionItemButton>;

    return <EuiPopover
        button={button}
        isOpen={isOpen}
        anchorPosition="downRight"
    >
        <div style={{width: 320}}>
            <EuiFlexGroup
                gutterSize="m"
                className="euiHeaderProfile"
                responsive={false}>
                <EuiFlexItem grow={false}>
                    <EuiAvatar name={`${decodeAuthToken()?.username}`}
                               size="xl"
                               imageUrl={`${process.env.REACT_APP_BACKEND_URL}/static/images/avatar/${decodeAuthToken()?.username}.jpeg`}
                    />
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiText>
                        <p>{`${decodeAuthToken()?.username}`}</p>
                    </EuiText>
                    <EuiSpacer size="m"/>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiFlexGroup justifyContent="spaceBetween">
                                <EuiFlexItem grow={false}>
                                    <EuiLink onClick={() => {
                                        setIsOpen(false);
                                        history.push('/settings')
                                    }}>Settings</EuiLink>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiLink onClick={() => logout()}>Log out</EuiLink>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </div>
    </EuiPopover>
};

export default Header;