import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import '@elastic/eui/dist/eui_theme_dark.css';
import {
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageContentHeader,
    EuiPageContentHeaderSection,
    EuiTitle,
    EuiImage,
    EuiFieldText,
    EuiFieldPassword,
    EuiForm,
    EuiFormRow,
    EuiButton,
    EuiSpacer,
    EuiLink,
    EuiText,
    EuiCallOut,
} from '@elastic/eui';
import AlphaBanner from "./AlphaBanner";

const backend_url = process.env.REACT_APP_BACKEND_URL;

const Login = ({ login}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [invalidLogin, setInvalidLogin] = useState(false);

    let history = useHistory();

    let emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    const submit = e => {
        e.preventDefault();
        let payload;
        if (emailPattern.test(email)) {
            payload = {email, password}
        } else {
            payload = {username: email, password}
        }
        login(payload).then(success => setInvalidLogin(!success))
    };

    const loginError = () => {
        return <>
            <EuiCallOut title="Something went wrong" color="danger" iconType="alert">
                <p>Invalid Email address or password!</p>
            </EuiCallOut>
            <EuiSpacer/>
        </>
    };

    return (
        <EuiPage>
            <EuiPageBody component="div">
                <AlphaBanner/>
                <EuiPageContent verticalPosition="center" horizontalPosition="center">
                    <EuiPageContentHeader>
                        <EuiPageContentHeaderSection>
                            <EuiImage
                                size="l"
                                alt="talhaflix.live"
                                url="https://i.imgur.com/2N2OWyg.png"
                            />
                        </EuiPageContentHeaderSection>
                    </EuiPageContentHeader>
                    <EuiPageContentBody>
                        {invalidLogin ? loginError() : null}
                        <EuiForm>
                            <EuiFormRow label="Email or Username">
                                <EuiFieldText name="email" onChange={(e) => setEmail(e.target.value)}/>
                            </EuiFormRow>
                            <EuiFormRow label="Password">
                                <EuiFieldPassword name="password" onChange={(e) => setPassword(e.target.value)}/>
                            </EuiFormRow>
                            <EuiButton type="submit" size="s" onClick={(e) => submit(e)}>
                                Login
                            </EuiButton>
                            <EuiSpacer size="s"/>
                            <EuiText size='s'><EuiLink onClick={() => {
                                history.push({pathname: '/register'})
                            }} size="s">Register</EuiLink></EuiText>
                        </EuiForm>
                    </EuiPageContentBody>
                </EuiPageContent>
            </EuiPageBody>
        </EuiPage>
    )
};

export default Login;