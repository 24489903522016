import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

//TODO
const backend_url = process.env.REACT_APP_BACKEND_URL;

export function isTokenValid(CB) {
    axios.get(backend_url + '/validateToken', {withCredentials: true})
        .then(response => {
            CB(true)
        })
        .catch(error => {
            CB(false)
        })
}

export function decodeAuthToken() {
    if(Cookies.get('token'))
        return jwt_decode(Cookies.get('token'));
    return null;
}