import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect, useHistory,
} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Login from "./components/Login";
import Register from "./components/Register";
import ProtectedRoute from "./components/ProtectedRoute";
import {isTokenValid} from "./service/authService"
import Streams from "./components/Streams";
import Settings from "./components/Settings";
import Stream from "./components/Stream";
import Header from "./components/Header";
import FeedbackButton from "./components/FeedbackButton"
import axios from "axios";

const backend_url = process.env.REACT_APP_BACKEND_URL;

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState('');
    let history = useHistory();


    const login = (payload) => axios.post(backend_url + '/login', payload, {withCredentials: true})
        .then(response => {
            setIsAuthenticated(true);
            history.push('/streams');
            return true;
        })
        .catch(error => {
            console.log(error);
            if (error.response?.status === 401)
                return false;
        });

    const logout = () =>
        axios.delete(backend_url + '/logout', {withCredentials: true})
            .then(() => {
                setIsAuthenticated(false);
                history.push('/login')
            }).catch(error => {
            console.log(error);
        });

    useEffect(() => {
        isTokenValid(authenticated => setIsAuthenticated(authenticated))
    }, []);

    return (
        <Router>
            {isAuthenticated ? <Header logout={logout}/> : null}
            {isAuthenticated ? <FeedbackButton/> : null}
            <Switch>
                <Route path="/login">
                    {
                        isAuthenticated ?
                            <Redirect to="/streams"/> :
                            <Login login={login} setIsAuthenticated={setIsAuthenticated}/>
                    }
                </Route>
                <Route path="/register" component={Register}/>

                <ProtectedRoute authenticated={isAuthenticated} path="/streams" render={() => {
                    return <Streams/>
                }}/>
                <ProtectedRoute authenticated={isAuthenticated} path="/stream/:username" render={() => {
                    return <Stream/>
                }}/>
                <ProtectedRoute authenticated={isAuthenticated} path="/settings" render={() => {
                    return <Settings/>
                }}/>
                <Route path="/">
                    <Redirect to="/login"/>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
